






























import Button from "../../commons/inputsAndControls/Button.vue";
import TextField from "../../commons/inputsAndControls/TextField.vue";
import Modal from "../../commons/Modal.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../../../enums";
import {
  CreateProcessTemplateInput,
  CreateProcessTemplateMutation,
  CreateProcessTemplateMutationVariables,
} from "../../../types/gqlGeneratedPrivate";
import { TableRefreshPayload } from "../../../types";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts, apiClient } from "../../../utils";
import { api } from "../../../api";
import { eventBus } from "../../../utils/eventBus";
import { ValidationObserver } from "vee-validate";

const emptyFormData: CreateProcessTemplateInput = {
  name: "",
  order: 0,
  active: false,
};

@Component({
  components: {
    Button,
    TextField,
    Modal,
    ValidationObserver,
  },
})
export default class ProcessTemplateCreateModal extends Vue {
  isOpen = false;
  isSending = false;

  formData: CreateProcessTemplateInput = {
    ...emptyFormData,
  };

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  created(): void {
    eventBus.$on(EventBus.ProcessTemplateCreateModal, () => {
      this.initForm();
      this.isOpen = true;
    });
  }

  async onSubmit(): Promise<void> {
    const valid = await (this.$refs.form as any).validate();
    if (valid) {
      this.sendForm(this.formData);
    }
  }

  async sendForm(formData: CreateProcessTemplateInput): Promise<void> {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      CreateProcessTemplateMutation,
      CreateProcessTemplateMutationVariables
    >({
      ...api.processTemplates.createProcessTemplate,
      variables: {
        input: formData,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("CREATE_PROCESS_TEMPLATE", "Uloženo");
      this.refreshTable({
        namespace: TablesNamespaces.ProcessTemplatesTable,
      });
    }
    this.initForm();
    this.isOpen = false;
  }

  initForm(): void {
    this.formData = {
      ...emptyFormData,
    };
    this.isSending = false;
    this.$nextTick(() => {
      if (this.$refs.form) {
        (this.$refs.form as any).reset();
      }
    });
  }

  cancel(): void {
    this.isOpen = false;
    this.initForm();
  }
}
