









import { Component, Vue } from "vue-property-decorator";
import ConfirmModal from "../../commons/ConfirmModal.vue";
import {
  DeleteProcessTemplateMutation,
  DeleteProcessTemplateMutationVariables,
  ProcessTemplate,
} from "../../../types/gqlGeneratedPrivate";
import { api } from "../../../api";
import { EventBus } from "../../../enums";
import { apiClient } from "../../../utils/apiClient";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { alerts } from "../../../utils";
import { Action } from "vuex-class";
import { TableRefreshPayload } from "../../../types";
import { eventBus } from "../../../utils/eventBus";

@Component({
  components: { ConfirmModal },
})
export default class ProcessTemplateDeleteModal extends Vue {
  EventBus = EventBus;
  isSending = false;

  payload = {
    name: "",
  };

  created() {
    eventBus.$on(EventBus.ProcessTemplateDeleteModal, (payload: any) => {
      this.payload = payload;
    });
  }

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  async onConfirm(payload: ProcessTemplate) {
    this.isSending = true;
    const result = await apiClient.callGraphqlPrivate<
      DeleteProcessTemplateMutation,
      DeleteProcessTemplateMutationVariables
    >({
      ...api.processTemplates.deleteProcessTemplate,
      variables: {
        id: payload.id,
      },
    });
    if (result.data) {
      alerts.addSuccessAlert("DELETE_PROCESS_TEMPLATE", "Smazáno");
      this.$emit("deleted");
      this.refreshTable({
        namespace: TablesNamespaces.ProcessTemplatesTable,
      });
    }
    this.isSending = false;
  }
}
