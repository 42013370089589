











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ConfirmModal from "../../commons/ConfirmModal.vue";
import { EventBus } from "../../../enums";
import { TablesActions, TablesNamespaces } from "../../../store/modules/tables";
import { Action } from "vuex-class";
import { TableRefreshPayload } from "../../../types";
import { eventBus } from "../../../utils/eventBus";

@Component({
  components: { ConfirmModal },
})
export default class ProcessTemplateActivateModal extends Vue {
  EventBus = EventBus;

  private localIsActive = false;

  @Prop({ required: true, type: Boolean, default: false })
  private isActive!: boolean;

  payload = {
    name: "",
  };

  created() {
    eventBus.$on(EventBus.ProcessTemplateActivateModal, (payload: any) => {
      this.payload = payload;
    });
    this.localIsActive = this.isActive;
  }

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  async onConfirm() {
    this.$emit("activated", this.localIsActive);
  }

  async onCancel() {
    this.localIsActive = !this.isActive;
    this.$emit("activated", this.localIsActive);
  }

  @Watch("isActive")
  onChanged(val: boolean) {
    this.localIsActive = val;
  }
}
