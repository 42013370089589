








































import ActionsWrapper from "../components/commons/tables/ActionsWrapper.vue";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import Button from "../components/commons/inputsAndControls/Button.vue";
import ProcessTemplateCreateModal from "../components/app/processTemplates/ProcessTemplateCreateModal.vue";
import ProcessTemplateDeleteModal from "../components/app/processTemplates/ProcessTemplateDeleteModal.vue";
import ProcessTemplateUpdateForm from "../components/app/processTemplates/ProcessTemplateUpdateForm.vue";
import RoundButton from "../components/commons/inputsAndControls/RoundButton.vue";
import SelectBox from "../components/commons/inputsAndControls/SelectBox.vue";
import Table from "../components/commons/tables/Table.vue";
import TableHeader from "../components/commons/tables/TableHeader.vue";
import { Action } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "../enums/EventBus";
import {
  ProcessTemplate,
  ProcessTemplatesFilterEnum,
} from "../types/gqlGeneratedPrivate";
import { TableOptions, TableRefreshPayload } from "../types";
import { TablesActions, TablesNamespaces } from "../store/modules/tables";
import { eventBus } from "../utils/eventBus";

interface UpdateProcessTemplate {
  id: number;
  name: string;
  order: number;
  active: number;
  processSubCodeIds: number[];
  processSubCodes: Array<{
    id: number;
    name: string;
    code: string;
  }>;
}

@Component({
  components: {
    ActionsWrapper,
    AppLayout,
    Button,
    ProcessTemplateCreateModal,
    ProcessTemplateDeleteModal,
    ProcessTemplateUpdateForm,
    RoundButton,
    SelectBox,
    Table,
    TableHeader,
  },
})
export default class ProcessTemplatesPage extends Vue {
  editedItem: UpdateProcessTemplate | null = null;
  isFormDirty = false;

  @Action(TablesActions.Refresh, {
    namespace: TablesNamespaces.ProcessTemplatesTable,
  })
  refreshTable!: (payload: TableRefreshPayload) => Promise<void>;

  tableOptions: TableOptions<ProcessTemplate, ProcessTemplatesFilterEnum> = {
    defaultSortBy: ["name"],
    headers: [
      {
        text: "Název",
        sortable: true,
        align: "start",
        value: "name",
      },
      {
        text: "",
        sortable: false,
        value: "actions",
      },
    ],
    namespace: TablesNamespaces.ProcessTemplatesTable,
    key: "id",
  };

  rowClick(row: any) {
    this.updateItem(row.item, row);
  }

  deleteItem(processTemplate: ProcessTemplate): void {
    eventBus.$emit(EventBus.ProcessTemplateDeleteModal, processTemplate);
  }

  addItem(): void {
    eventBus.$emit(EventBus.ProcessTemplateCreateModal);
  }

  updateItem(item: UpdateProcessTemplate, row: any): void {
    row.select();
    this.editedItem = { ...item };
  }

  onItemDeleted() {
    this.editedItem = null;
    this.isFormDirty = false;
  }

  onItemUpdated() {
    this.refreshTable({
      namespace: TablesNamespaces.ProcessTemplatesTable,
    });
    this.isFormDirty = false;
  }

  onFormChanged() {
    this.isFormDirty = true;
  }
}
